var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.newEmployee},[_c('Title',{attrs:{"text":"Создание сотрудника","position":"right"}}),(_vm.loading)?_c('div',{class:_vm.$style.newEmployee__loaderIndicator},[_c('Loading')],1):_c('div',[_c('h3',[_vm._v("ФИО")]),_c('div',{class:_vm.$style.newEmployee__row},[_c('Input',{class:_vm.$style.newEmployee__input,attrs:{"placeholder":"Фамилия*","error":_vm.errors.firstName && !_vm.firstFill},on:{"input":function($event){return _vm.setText('lastName')}},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('Input',{class:_vm.$style.newEmployee__input,attrs:{"placeholder":"Имя*","error":_vm.errors.firstName && !_vm.firstFill},on:{"input":function($event){return _vm.setText('firstName')}},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('Input',{class:_vm.$style.newEmployee__input,attrs:{"placeholder":"Отчество"},model:{value:(_vm.middleName),callback:function ($$v) {_vm.middleName=$$v},expression:"middleName"}})],1),_c('div',{class:_vm.$style.newEmployee__row},[_c('div',[_c('h3',[_vm._v("Телефон")]),_c('vue-tel-input',{class:_vm.$style.newEmployee__input,staticStyle:{"height":"34px"},attrs:{"mode":"international","input-options":{
            maxlenght: 25,
            autocomplete: 'on',
            showDialCode: true,
            type: 'tel',
            id: '',
            name: 'telephone',
            tabindex: '0',
            'aria-descibedby': ''
          }},on:{"input":(v) => _vm.setPhone(v),"validate":(v) => _vm.validatePhone(v)},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('div',[_c('h3',[_vm._v("Почта")]),_c('Input',{class:_vm.$style.newEmployee__input,attrs:{"placeholder":"________@____.__*","error":_vm.errors.email && !_vm.firstFill},on:{"input":_vm.setEmail},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('div',[_c('h3',[_vm._v("Роль")]),_c('Select',{class:_vm.$style.newEmployee__input,attrs:{"placeholder":"Не заполнено*","items":_vm.roles},on:{"input":(val) => (_vm.roleId = val?.id ?? val)},model:{value:(_vm.roleId),callback:function ($$v) {_vm.roleId=$$v},expression:"roleId"}})],1)]),_c('div',{class:_vm.$style.newEmployee__buttons},[_c('Button',{class:_vm.$style.newEmployee__buttons_button,attrs:{"type":'primary'},on:{"click":_vm.create}},[_vm._v(" Создать нового сотрудника ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }