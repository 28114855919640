<template>
  <div :class="$style.newEmployee">
    <Title text="Создание сотрудника" position="right" />

    <div v-if="loading" :class="$style.newEmployee__loaderIndicator">
      <Loading />
    </div>

    <div v-else>
      <h3>ФИО</h3>
      <div :class="$style.newEmployee__row">
        <Input
          v-model="lastName"
          :class="$style.newEmployee__input"
          placeholder="Фамилия*"
          :error="errors.firstName && !firstFill"
          @input="setText('lastName')"
        />
        <Input
          v-model="firstName"
          :class="$style.newEmployee__input"
          placeholder="Имя*"
          :error="errors.firstName && !firstFill"
          @input="setText('firstName')"
        />
        <Input
          v-model="middleName"
          :class="$style.newEmployee__input"
          placeholder="Отчество"
        />
      </div>

      <div :class="$style.newEmployee__row">
        <div>
          <h3>Телефон</h3>
          <vue-tel-input
            v-model="phone"
            mode="international"
            style="height: 34px"
            :class="$style.newEmployee__input"
            :input-options="{
              maxlenght: 25,
              autocomplete: 'on',
              showDialCode: true,
              type: 'tel',
              id: '',
              name: 'telephone',
              tabindex: '0',
              'aria-descibedby': ''
            }"
            @input="(v) => setPhone(v)"
            @validate="(v) => validatePhone(v)"
          ></vue-tel-input>
        </div>
        <div>
          <h3>Почта</h3>
          <Input
            v-model="email"
            :class="$style.newEmployee__input"
            placeholder="________@____.__*"
            :error="errors.email && !firstFill"
            @input="setEmail"
          />
        </div>
        <div>
          <h3>Роль</h3>
          <Select
            v-model="roleId"
            :class="$style.newEmployee__input"
            placeholder="Не заполнено*"
            :items="roles"
            @input="(val) => (roleId = val?.id ?? val)"
          />
        </div>
      </div>

      <div :class="$style.newEmployee__buttons">
        <Button :class="$style.newEmployee__buttons_button" :type="'primary'" @click="create">
          Создать нового сотрудника
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@/basic/Title'
import Input from '@/basic/Input'
import Button from '@/basic/Button'
import Loading from '@/basic/Loading.vue'
import Select from '@/basic/DefaultSelect'
import { VueTelInput } from 'vue-tel-input'

import employeesApi from '@/api/employees'
import { EmployeeNames } from '@/application/constants'

export default {
  name: 'NewEmployee',
  components: {
    Title,
    Button,
    Input,
    Loading,
    VueTelInput,
    Select
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      middleName: null,
      phone: null,
      email: null,
      roleId: null,
      validPhoneLength: 11,
      firstFill: true,
      errors: {
        firstName: true,
        lastName: true,
        phone: true,
        email: true
      },
      loading: false,
      roles: EmployeeNames
    }
  },
  methods: {
    setText(text) {
      if (this[text]?.length >= 2) {
        this.errors[text] = false
      } else {
        this.errors[text] = true
      }
    },
    setPhone() {
      if (this.phone?.replace(/\D/g, '').length === this.validPhoneLength) {
        this.errors.phone = false
      } else {
        this.errors.phone = true
      }
    },
    setEmail() {
      if (/.+@.+\.[A-Za-z]+$/.test(this.email)) {
        this.errors.email = false
      } else {
        this.errors.email = true
      }
    },
    validate() {
      return (
        this.firstName &&
        this.lastName &&
        this.roleId &&
        /.+@.+\.[A-Za-z]+$/.test(this.email)
      )
    },

    async create() {
      if (!this.validate()) {
        await this.$store.dispatch('openModal', [
          'Alert',
          { title: 'Ошибка!', message: 'Заполните все обязательные поля!' }
        ])
        this.firstFill = false
        return false
      }

      try {
        this.loading = true
        const { data } = await employeesApi.checkEmployeePhoneOrEmail({
          email: this.email
        })
        const email = data?.result?.email === false

        if (email) {
          await this.$store.dispatch('openModal', [
            'Alert',
            {
              title: 'Ошибка!',
              message: `Пользователь с данной почтой уже существует!`
            }
          ])
          return
        }

        const {
          data: { success, result }
        } = await employeesApi.createEmployee({
          email: this.email,
          phone: this.phone.replace(/\D/g, '').length > 3 ? this.phone.replace(/\D/g, '') : null,
          roleId: this.roleId,
          firstName: this.firstName,
          lastName: this.lastName,
          middleName: this.middleName
        })
        if (success && result) {
          await this.$store.dispatch('openModal', [
            'Alert',
            { title: 'Успех', message: 'Менеджер был успешно создан!' }
          ])
          await this.$router.push(`/employees?page=1`)
        }
      } catch (e) {
        console.warn(e)
        await this.$store.dispatch('openModal', [
          'Alert',
          { title: 'Ошибка', message: 'Ошибка получения данных с сервера!' }
        ])
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.error {
  border: 1px solid red;
}
.newEmployee {
  &__loaderIndicator {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    padding: 1.125rem 2rem;
    background: white;
  }
  &__row {
    display: flex;
  }
  &__input {
    width: 16rem;
    margin: 0.5rem 1rem 1.5rem 0;
    margin-left: 0 !important;
  }
  &__buttons {
    width: 100%;
    display: flex;
    &_button {
      width: auto !important;
      font-size: 1rem !important;
      text-align: center !important;
      margin: 0 !important;
    }
  }
}
</style>
